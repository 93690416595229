<template>
  <div>

   <h2>Apotek</h2>

  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Apotek" }])
    // Get Data
    this.list()
  },

}

</script>
